import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth';
import { getFirestore, initializeFirestore, persistentLocalCache, persistentSingleTabManager } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAZBn-bz1Sk8dbK7AYnwx02Q6A76Q0Ceak",
  authDomain: "lognow-controlo.firebaseapp.com",
  projectId: "lognow-controlo",
  storageBucket: "lognow-controlo.appspot.com",
  messagingSenderId: "41207915465",
  appId: "1:41207915465:web:8db3b0a910d67be7697e18"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);

// Initialize Firestore with persistent cache
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache(
    { tabManager: persistentSingleTabManager() }
  )
});

export const storage = getStorage(app);

// Setup auth state listener to share token between domains
onAuthStateChanged(auth, async (user) => {
  try {
    if (user) {
      // When user is signed in, get a fresh token and store it
      const token = await user.getIdToken(true);
      localStorage.setItem('lognow_auth_token', token);
    } else {
      localStorage.removeItem('lognow_auth_token');
    }
  } catch (err) {
    console.error('Error getting auth token:', err);
    localStorage.removeItem('lognow_auth_token');
  }
});

// Function to check and restore auth state
export const restoreAuthState = async () => {
  try {
    // Check if we have a stored token and no current user
    const token = localStorage.getItem('lognow_auth_token');
    if (!token || auth.currentUser) return;

    // Check if we're in support mode
    const supportSession = sessionStorage.getItem('supportSession');
    if (supportSession) {
      try {
        const session = JSON.parse(supportSession);
        if (session?.timestamp && Date.now() - session.timestamp < 3600000) { // 1 hour
          return; // Don't restore if in valid support session
        }
      } catch (err) {
        console.error('Invalid support session:', err);
      }
      // Clear expired/invalid support session
      sessionStorage.removeItem('supportSession');
      localStorage.removeItem('adminSession');
    }

    // Sign in with stored token
    await signInWithCustomToken(auth, token);
  } catch (err) {
    console.error('Error restoring auth state:', err);
    // Clear invalid token and sessions
    localStorage.removeItem('lognow_auth_token');
    sessionStorage.removeItem('supportSession');
    localStorage.removeItem('adminSession');
  }
};

export default app;